@import "_bootstrap-sass-grid";
@import "./libs/fonts";
@import "./libs/magnificPopup.css";
@import "./libs/owl.carousel.min.css";

$breakpoint-lg: 1200px;
$breakpoint-sm:990px;
$breakpoint-tablet:910px;
$breakpoint-xs:760px;

$breakpoint-menu:610px;
$breakpoint-container:990px;


html,body{
    overflow-x: hidden;
}
body{
	background-color: #24282b;
	color: white;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 100;
    min-height: 100vh;
    i.icon {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'iconpack' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &.icon-close:before {
        content: "\e903";
      }
      &.icon-bars:before {
        content: "\e904";
      }
      &.icon-arrow:before {
        content: "\e900";
      }
      &.icon-error:before {
        content: "\e901";
      }
      &.icon-ok:before {
        content: "\e902";
      }

    }
    .mobile-btn{
        display: none;
    }
    hr{
        border: none;
        border-bottom: 1px solid rgba(255,255,255,.4);
    }
    .noPadding{
        padding: 0px !important;
        margin: 0px !important;
    }
    .noPaddingLeft{
        padding-left: 0px !important;
    }
    ul.defaultUl{
        margin: 0px;
        padding: 0px;
        -webkit-margin-before: 0px;
        -webkit-margin-after: 0px;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 0px;
        li{
            list-style: none;
            margin: 0px;
            margin-top: 3px;
            margin-bottom: 3px;
            padding-left: 0px;
            padding: 3px;
            &.bg{


            }
        }
        &:nth-child(odd){
            li.bg{
                background-color: rgba(255,255,255,.3);
            }
        }
    }
    .responsive-uls{
        ul{
            text-align: left;
            list-style: none;
            margin: 0px;
            li{
                line-height: 40px;
            }
            &:nth-child(even){
                li{
                    background-color: rgba(255,255,255,.6);
                }

            }
            &.title{
                li{
                    background-color: #d0b26b;
                    color: white;
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }
    }
    .text-center{
        text-align: center;
        img{
            margin-left: auto;
            margin-right: auto;
        }
    }
    .text-right{
        text-align: right;
    }
    .textColor{
        color: #f1b402;
    }
    .img-responsive{
        width: 100%;
        height: auto;
        padding: 0px;
        margin: 0px;
    }
    .blank{
        width: 100%;
        height: 50px;
    }
    .bold{
        font-weight: bold;
    }
    h1,h2,h3,h4{
        font-size: 30px;
        color: white;
        line-height: 35px;
    }
    h3{
        font-size: 20px;

        line-height: 40px;
    }
    h4{
        font-size: 25px;
        line-height: 30px;
    }
    .photos{
        display: block;
        margin-bottom: 20px;
    }
    .m-b-15{
        margin-bottom: 15px;
    }
    .m-b-40{
        margin-bottom: 40px;
    }
    .btns{
        border-radius: 4px;
        background-color: #d0b26b;
        color: white;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        text-decoration: none;
        font-size: 13px;
    }
    .contact-form{
        background-color: #d0b26b;
        color: white;
        h1,h2,h3{
            color: white;
        }
        a{
            color: white;
        }
    }
    .form-group{
        &.error{
            input,textarea{
                border: 1px solid #8d0000;
            }
            p{
                margin: 0px;
                ul{
                    -webkit-padding-start: 0px;
                    list-style: none;
                    margin: 0px;
                    color: red;
                }
            }
        }
        input,textarea{
            width: 100%;
            padding: 5px;
            background-color: #2a2e31;
            border: 1px solid #353b3f;
            line-height: 30px;
            color: black;
            font-size: 15px;
            color: white;
        }
        textarea{
            height: 100px;
            line-height: 20px;
            color: white;
        }
        button{
            border: none;
            color: white;
            text-align: center;
            font-weight: bold;
            padding: 15px;
            background: #2c810b; /* Old browsers */
            background: -moz-linear-gradient(left, #2c810b 0%, #6fbd00 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #2c810b 0%,#6fbd00 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, #2c810b 0%,#6fbd00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c810b', endColorstr='#6fbd00',GradientType=1 ); /* IE6-9 */
        }
        p.help-block{
            font-size: 13px;
            padding: 0px;
            margin: 0px;
            height: 24px;
            display: block;
            width: 100%;
        }
    }
    /* START head options */
    #head{
        width: 100%;
        position: absolute;
        z-index: 999;
        background-color: rgba(0,0,0,.6);
        a.logo{
            margin-top: 15px;
            display: block;
            img{
                max-width: 170px;
            }
        }
        ul.nav{
            list-style: none;
            margin-top: 0px;
            li{
                display: inline-block;
                padding-top: 25px;
                padding-bottom: 10px;
                &::after{
                    content: "/";
                    color: rgba(255,255,255,.3);
                    padding-left: 10px;
                    padding-right: 10px;
                }
                &:last-child::after{
                    display: none;
                }
                a{

                    color: white;
                    font-weight: 100;
                    font-size: 18px;
                    text-decoration: none;
                    padding-bottom: 5px;
                    &:hover{
                        border-bottom: 1px solid white;
                    }
                }
            }
        }
    }
    /* END head options*/
    #slider{
        background: #2c810b; /* Old browsers */
        background: -moz-linear-gradient(left, #2c810b 0%, #6fbd00 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #2c810b 0%,#6fbd00 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #2c810b 0%,#6fbd00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c810b', endColorstr='#6fbd00',GradientType=1 ); /* IE6-9 */
    }
    /* START data options */
    #data{
        padding-top: 20px;
        padding-bottom: 20px;
        background: #2c810b; /* Old browsers */
        background: -moz-linear-gradient(left, #2c810b 0%, #6fbd00 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #2c810b 0%,#6fbd00 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #2c810b 0%,#6fbd00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c810b', endColorstr='#6fbd00',GradientType=1 ); /* IE6-9 */
    }
    /* END data options */
    /* START footer options */
    #footer{
        background-color: #24282b;
        padding-top: 40px;
        padding-bottom: 30px;
        h2{
            padding-top: 0px;
            margin-top: 0px;
        }
        .team{
            font-size: 14px;
            margin-bottom: 45px;
            img{
                border-radius: 40px;
                margin-right: 15px;
            }
            big,a{
                display: block;
            }
            big{
                font-weight: 500;
                color: #64b302;
            }
            a{
                color: white;
                text-decoration: none;
            }
        }
        .copyright{
            margin-top: 15px;
            display: block;
            border-top: 1px solid rgba(255,255,255,.2);
            font-size: 13px;
            a{
                color: #64b302;
                text-decoration: none;
                border-bottom: 1px solid #64b302;
                padding-bottom: 5px;
            }
        }
    }
    /* END footer options */
    #moda{
        background-color: rgba(0,0,0,.6);
        position: absolute;
        top: 0px;
        width: 100%;
        min-height: 100vh;
        z-index: 999;
        display: none;
        &.active{
            display: block;
        }
        .modal-body{
            margin-top: 60px;
            color: #64b302;
            background-color: white;
            i.icon-ok{
                font-size: 50px;
                display: block;
                margin-top: 15px;
            }
            a.close-modal{
                position: absolute;
                right: 0px;
                margin-top: -40px;
                color: white;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }
}
@media screen and (max-width:$breakpoint-menu) {

}
@media screen and (max-width:$breakpoint-sm) {
    #head{
        .nav{
            li{
                line-height: 59px;
                padding: 0px !important;
            }
        }
    }
}
@media screen and (max-width:435px) {
    #head{
        a.logo{
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 10px !important;
            margin-bottom: 10px;
        }
        .nav{
            display: none !important;
            border-top: 1px solid rgba(255,255,255,.3);
            &.active{
                display: block !important;
                float: none !important;
                li{
                    display: block !important;
                    width: 100% !important;
                    text-align: left !important;
                    &::after{
                        content: "";
                    }
                }
            }
        }
        .mobile-btn{
            position: absolute;
            right: 15px;
            top: 10px;
            display: block;
            font-size: 30px;
            line-height: 45px;
            cursor: pointer;
        }
    }
}
@media screen and (max-width:$breakpoint-xs) {
    h1,h2{
        text-align: center;
    }
    .form-group{
        padding: 0px !important;
        button{
            width: 100%;
        }
    }
    #footer{
        .team{
            text-align: center;
            img{
                float: none !important;
            }
        }
    }
}
